import { URL } from "@api/doinsport/services/bookings";
import { _delete, _post, _put } from "@api/doinsport/services/httpService";

const PARTICIPANT_URL = URL + '/participants';

export const postParticipant = (participant) => _post(PARTICIPANT_URL, participant);

export const deleteParticipant = (id) => _delete(URL + '/' + id);

export const putParticipant = (id, data) => _put(PARTICIPANT_URL + '/' + id, data);
