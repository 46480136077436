import { render, staticRenderFns } from "./ParticipantsHeader.vue?vue&type=template&id=2380ef47&scoped=true&"
import script from "./ParticipantsHeader.vue?vue&type=script&lang=js&"
export * from "./ParticipantsHeader.vue?vue&type=script&lang=js&"
import style0 from "./ParticipantsHeader.vue?vue&type=style&index=0&id=2380ef47&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2380ef47",
  null
  
)

export default component.exports