<template>
  <div class="container-fluid">
    <b-row class="mt-3 mb-2">
      <b-col cols="12">
        <p class="resume-label"> {{ $t('views.planning.payment.payment') }}</p>
      </b-col>
    </b-row>
    <div
      ref="activitiesScroll"
      class="container-scroll"
    >
      <b-row
        v-if="bookingDetails"
      >
        <template
          v-if="bookingDetails.payments.length > 0"
          v-for="(payment, i) of bookingDetails.payments"
        >
          <b-col
            :key="'col - ' + i"
            v-if="isParticipantPayment(payment.participantId)"
            class="mt-1"
            cols="12"
          >
            <div class="">
                <span class="payment-label">
                  {{ formatCreatedAt(payment.payment.createdAt) }}
                </span>
              -
              <span
                v-if="payment.payment.paymentTokenOperationDetail === null"
                class="payment-amount"
              >
                  {{ (payment.payment.amount / 100).toFixed(2) + $currency }}
                </span>
              <span
                v-else
                class="payment-amount"
              >
                  {{ payment.payment.paymentTokenOperationDetail.amount }}
                    x
                  {{ payment.payment.paymentTokenOperationDetail.name }}
                </span>
              <span class="payment-amount">
                ({{ paymentMethods(payment.payment) }})
              </span>
            </div>
          </b-col>
        </template>
      </b-row>
    </div>
    <b-row class="mt-2">
      <b-col cols="12">
        <add-link
          :no-align="true"
          pointer="pointer"
          class-icon="fa fa-plus text-right text-blue-darken"
          text-link="views.planning.payment.add-payment"
          @on:add-link-click="$emit('on:add-regulation')"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {fromIdToIriReference} from "@/utils/form";
import Payment from "@/classes/doinsport/Payment";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import AddLink from "@custom/AddLink";

export default {
  components: {AddLink},
  props: {
    bookingDetails: {
      type: Object,
      default: null
    },
    participant: {
      type: Object,
      default: null
    }
  },
  methods: {
    isParticipantPayment(participantId) {
      return this.participant.iriParticipant === fromIdToIriReference('/clubs/bookings/participants', participantId);
    },
    paymentMethods(item) {
      const payment = new Payment();
      const paymentMethod = payment.paymentMethod(item);

      return this.$t(paymentMethod);
    },
    formatCreatedAt(date) {
      if (isNotUndefinedAndNotNull(date)) {
        let day = this.$moment.utc(date).format('dddd');

        return day.charAt(0).toUpperCase() + day.slice(1) + ' ' + this.$moment.utc(date).locale(this.$i18n.locale).format('LL').replace(' 00:00', '');
      }
    },
  }
}
</script>

<style scoped lang="scss">
.resume-label {
  font: normal normal 900 20px Avenir;
  color: #4D4F5C;
}

.payment-label {
  font: normal normal normal 16px Avenir;
}
.payment-amount {
  font: normal normal 900 16px Avenir;
}
</style>
