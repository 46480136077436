import {hydrate} from "@/utils/form";

export default class Participant {
  id = null;
  user = null;
  price = null;
  client = null;
  addedBy = null;
  canceled = null;
  customData = [];
  category = null;
  restToPay = null;
  subscriptionCard = null;
  confirmedParticipation = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize(object) {
    hydrate(this, object);
  }
}
