import {hydrate} from "@/utils/form";

export const DEFAULT_BOOKING_TYPE = 'default';
export const LESSON_BOOKING_TYPE = 'lesson';
export const SPORT_BOOKING_TYPE = 'sport';
export const LEISURE_BOOKING_TYPE = 'leisure';
export const FORMULA_BOOKING_TYPE = 'formula';

export default class BookingModel {
  id = null;
  name = null;
  startAt = null;
  endAt = null;
  activity = null;
  category = null;
  timetableBlockPrice = null;
  participants = [];
  comment = null;
  playgrounds = [];
  recurrence = null;
  fromRecurrence = null;
  participantsQueueEnabled = false;
  client = null;
  fixedParticipantsCount = null;
  club = null;
  creationOrigin = null;
  paymentMethod = null;
  playgroundOptions = [];
  coaches = [];
  registrationAvailableOnline = true;
  maxParticipantsCountLimit = null;
  registrationTimeBeforeStart = null;
  nameManuallyUpdated = null;

  constructor(object = null) {
    if (object) {
      this.serialize(object);
    }
  }

  serialize(object) {
    hydrate(this, object);
  }
}
