<template>
  <div>
    <div
      v-if="!isFormulaType"
      :class="innerWidth < 991 ? 'mt-2 pl-2' : ''"
      class="container-fluid"
    >
      <b-row
        :class="innerWidth < 991 ? 'm-0 p-0' : ''"
        class="container-fluid "
      >
        <b-col
          :class="innerWidth < 991 ? 'pr-2' : ''"
        >
          <div class="mt-2">
            <b-row :class="innerWidth < 991 ? 'mt-2 mb-2' : 'mb-1'">
              <b-col class="field-name-class">
                {{ $t('components.custom.planning.booking-details-modal.general-information.add-participant') }}
              </b-col>
            </b-row>
            <b-row>
              <b-col
                :class="innerWidth < 991 ? '' : 'margin-auto'"
                :cols="innerWidth < 991 ? 12 : ''"
              >
                <customer-list-search
                  :items="items"
                  :booking="booking"
                  class-name="background-blue-inputs"
                  icon-color="#0B2772"
                  @on:participant-added:from-slide="onParticipantAddedFromList"
                />
              </b-col>
              <b-col
                :cols="innerWidth < 991 ? '6' : '3'"
                :align="innerWidth < 991 ? '' : 'center'"
                :class="innerWidth < 991 ? 'pr-1' : ''"
              >
                <div class="mt-1">
                  <d-dropdown-form
                    :key="'d-drop-down' + dropDownKey"
                    :show="show"
                    :hide="hide"
                    :min-width="minWidth"
                    identifier="custom-client"
                  >
                    <template v-slot:dropdownbutton>
                        <span
                          :class="innerWidth > 700 ? 'field-name-class pointer' : 'color-primary-custom d-btn-lg font-text-title btn button-custom-width'"
                          @on:button-click="show = !show"
                        >
                          <i
                            v-if="innerWidth > 700"
                            class="font-size-larger fa fa-user-plus"
                          ></i>
                          <label :class="innerWidth > 700 ? 'pointer mt-1 ml-2 font-size-larger btn-label' : 'mb-0'">
                            {{ shortTextClient }}
                          </label>
                        </span>
                    </template>
                    <template v-slot:dropdownbody>
                      <custom-form
                        :key="fromKey"
                        :custom-client="customClient"
                        @on:form-submit="onCustomerAdded"
                      />
                    </template>
                  </d-dropdown-form>
                </div>
              </b-col>
              <b-col
                :cols="innerWidth < 991 ? '6' : ''"
                :align="innerWidth < 991 ? '' : 'center'"
                :class="innerWidth < 991 ? 'pl-1' : ''"
              >
                <div class="mt-1">
            <span
              :class="innerWidth > 700 ? 'field-name-class pointer' : 'color-primary-custom-light d-btn-sm font-text-title btn button-custom-width'"
              @click="addVisitor"
            >
                <i
                  v-if="innerWidth > 700"
                  class="font-size-larger fa fa-user-plus"
                ></i>
                <label :class="innerWidth > 700 ? 'ml-2 pointer font-size-larger btn-label mt-1' : 'mb-0'">
                  {{ shortTextVisitor }}
                </label>
              </span>
                </div>
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
      <b-row
        class="container-fluid"
        :class="innerWidth < 991 ? 'mt-2' : ''"
      >
        <b-col>
          <div class="mt-2">
            <b-row :class="innerWidth < 991 ? 'mt-2 mb-2' : 'mb-1'">
              <b-col class="field-name-class">
                {{ $t('components.custom.planning.booking-details-modal.general-information.visitors') }}
              </b-col>
            </b-row>
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row class="custom-flex-row">
      <b-col>
        <participants-table
          :items="items"
          :is-busy="isBusy"
          :booking="booking"
          :position="position"
          :show-regulation="showRegulation"
          :refresh-participants="refreshParticipants"
          :timetable-block-price="timetableBlockPrice"
          :canceled-participants="canceledParticipants"
          @on:participant-deleted="onParticipantDeleted"
          @on:payment-succeeded="loadBookingParticipants"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <rate-resume
          v-if="timetableBlockPrice && !this.isBusy"
          :booking="booking"
          :participants="items"
          :variations="variations"
          :timetable-block-price="timetableBlockPrice"
        />
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col align="right" cols="12" class="grey-container">
        <div class="d-flex justify-content-end">
          <label class="price-label mt-2 mr-3">
            {{ $t('total-amount') }} {{ totalAmount }}
            {{ $currency }}
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col align="right" cols="12" class="white-container">
        <div class="d-flex justify-content-end">
          <label class="price-label mt-2 mr-3">
            {{ $t('paid-amount') }} {{ paidAmount }}
            {{ $currency }}
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row>
      <b-col align="right" cols="12" class="grey-container">
        <div class="d-flex justify-content-end">
          <label class="price-label mt-2 mr-3">
            {{ $t('due-amount') }} {{ restToPayAmount }}
            {{ $currency }}
          </label>
        </div>
      </b-col>
    </b-row>

    <payment-history
      v-if="isFormulaType && null !== participantPayment"
      :participant="participantPayment"
      :booking-details="booking"
      @on:add-regulation="showRegulation = !showRegulation"
    />
  </div>
</template>
<script>
import {hydrate} from "@/utils/form";
import {capitalize, toFloatFixed} from "@/utils/string";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {_put} from "@api/doinsport/services/httpService";
import Participant from "@/classes/doinsport/Participant";
import {FORMULA_BOOKING_TYPE, LEISURE_BOOKING_TYPE, LESSON_BOOKING_TYPE} from "@/classes/doinsport/BookingModel";
import {postParticipant} from "@api/doinsport/services/bookings/participant/participant.api";
import {getBooking, getParticipantsByBooking} from "@api/doinsport/services/bookings/booking.api";
import PaymentHistory from "@custom/planning/PaymentHistory";

const MAX_DROPDOWN_WIDTH = 334;
const MIN_DROPDOWN_WIDTH = 250;

export default {
  components: {
    PaymentHistory,
    CustomForm: () => import('@form/client/CustomForm'),
    ParticipantsTable: () => import('./ParticipantsTable'),
    RateResume: () => import('@views/planning/schedule/playgrounds/bookings/details/RateResume'),
    CustomerListSearch: () => import('@form/booking/create-or-update/participants/CustomerListSearch')
  },
  props: {
    booking: {
      type: Object,
      default: this,
    },
    timetableBlockPrice: {
      type: Object,
      default: null,
    },
    canceledParticipants: {
      type: Array,
      default: () => [],
    },
    alreadyCanceled: {
      type: Array,
      default: () => [],
    },
    variations: {
      type: Array,
      default: () => [],
    },
    position: {
      type: String,
      default: 'left'
    },
  },
  data: () => ({
    customerToPutOrToCreateFromList: null,
    participantAction: false,
    dropDownKey: 0,
    fromKey: 0,
    items: [],
    show: false,
    showRegulation: false,
    dataModal: null,
    display: false,
    hideModal: false,
    isBusy: false,
    hide: false,
    refreshParticipants: false,
    defaultDropDownSize: 250,
    size: 250,
    customClient: {
      firstName: null,
      lastName: null,
      phoneNumber: null,
      email: null,
    },
  }),
  computed: {
    participantPayment() {
      if (this.items.length) {
        for (const item of this.items) {
          if (item.client) {
            return item;
          }
        }
      }

      return null;
    },
    isFormulaType() {
      if (isNotUndefinedAndNotNull(this.timetableBlockPrice)) {
        return this.timetableBlockPrice.activityType === FORMULA_BOOKING_TYPE;
      }

      return false;
    },
    shortTextClient() {
      if (this.innerWidth > 991) {
        return this.$t('views.client.index.new.default');
      } else {
        return this.$t('components.custom.planning.booking-details-modal.general-information.add-new-short');
      }
    },
    shortTextVisitor() {
      if (this.innerWidth > 991) {
        return this.$t('components.custom.planning.booking-details-modal.general-information.add-visitor');
      } else {
        return this.$t('components.custom.planning.booking-details-modal.general-information.visitor-short');
      }
    },
    totalAmount() {
      return toFloatFixed(this.booking.price);
    },
    restToPayAmount() {
      return toFloatFixed(this.booking.restToPay);
    },
    paidAmount() {
      return (this.totalAmount - this.restToPayAmount).toFixed(2);
    },
    minWidth() {
      if (this.customClient.email === null && this.customClient.phoneNumber === '') {
        return MIN_DROPDOWN_WIDTH + 'px';
      } else {
        if (!((this.customClient.email && this.customClient.email.length >= 29) || (this.customClient.phoneNumber && this.customClient.phoneNumber.length >= 29))) {
          if (this.customClient.phoneNumber && this.customClient.phoneNumber.length > 13 && this.customClient.phoneNumber.length < 24) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.phoneNumber.length;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.phoneNumber && this.customClient.phoneNumber.length >= 24 && this.customClient.phoneNumber.length < 29) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.phoneNumber.length * 3;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.phoneNumber && this.customClient.phoneNumber.length >= 29) {
            return MAX_DROPDOWN_WIDTH + 'px';
          }
          if (this.customClient.email && this.customClient.email.length > 13 && this.customClient.email.length < 24) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.email.length + 2;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.email && this.customClient.email.length >= 24 && this.customClient.email.length < 29) {
            this.defaultDropDownSize = MIN_DROPDOWN_WIDTH + this.customClient.email.length * 3;
            return this.defaultDropDownSize + 'px';
          }
          if (this.customClient.email && this.customClient.email.length >= 29) {
            return MAX_DROPDOWN_WIDTH + 'px';
          }
          return MIN_DROPDOWN_WIDTH + 'px';
        } else {
          return MAX_DROPDOWN_WIDTH + 'px';
        }
      }
    },
    selectedPlaygroundName() {
      const playground = this.$store.getters["playgrounds/getPlaygrounds"].find(el => el['@id'] === this.booking.playgrounds[0]);

      return isNotUndefinedAndNotNull(playground) ? playground.name : '';
    },
    innerWidth() {
      return this.$store.getters["layout/getInnerWidth"];
    },
    blockPrices() {
      return this.$store.getters["playgrounds/getSelectedBlockPrices"];
    }
  },
  methods: {
    onParticipantAddedFromList(customer) {
      let findParticipant = null;

      for (const participant of this.alreadyCanceled) {
        if (participant.client) {
          if (participant.client['@id'] === customer.iri) {
            findParticipant = participant;
          }
        }
      }

      if (null !== findParticipant) {
        _put(findParticipant['@id'], {canceled: false})
          .then((response) => {
            this.loadBookingParticipants();
          })
        ;
      } else {
        let subscriptionCard = null;

        if (customer.item.subscriptionCardsAvailable) {
          subscriptionCard = this.getSubscriptionCard(customer.item);
        }
        const blockPriceCategories = this.$store.getters['multipleBooking/getPriceCategories'];

        const data = {
          client: customer.item['@id'],
          subscriptionCard: subscriptionCard ? subscriptionCard['@id'] : null,
          booking: this.booking['@id'],
          category: blockPriceCategories.length === 1 ? blockPriceCategories[0].category['@id'] : null
        };

        postParticipant(data)
          .finally(() => {
            this.loadBookingParticipants();
          })
        ;
      }
    },
    isValidSubCard(subscriptionCard) {
      const bookingStartAt = this.$moment(this.booking.startAt);
      const bookingEndAt = this.$moment(this.booking.endAt);
      const startingDate = this.$moment(subscriptionCard.startDate);
      const endingDate = this.$moment(subscriptionCard.endDate);

      return bookingStartAt.isSameOrBefore(endingDate) && bookingEndAt.isSameOrAfter(startingDate);
    },
    getSubscriptionCard(participant) {
      let variationUsed = null;
      let subscriptionCardUsed = null;
      let price = 0;

      if (participant && participant.subscriptionCardsAvailable) {
        const bookingBlockPrice = this.timetableBlockPrice;

        for (const subCard of participant.subscriptionCardsAvailable) {

          if (bookingBlockPrice && this.isValidSubCard(subCard)) {
            price = bookingBlockPrice.pricePerParticipant;


            let variations = this.variations.sort((a, b) => a.pricePerParticipant - b.pricePerParticipant);

            if (variations) {
              for (const variation of variations) {
                if (variation.subscriptionPlan['@id'] === subCard.subscriptionPlan['@id']) {
                  if (variationUsed === null) {
                    variationUsed = variation;
                    price = variation.pricePerParticipant;
                    subscriptionCardUsed = subCard;
                  } else if (variationUsed.pricePerParticipant > variation.pricePerParticipant) {
                    variationUsed = variation;
                    price = variation.pricePerParticipant;
                    subscriptionCardUsed = subCard;
                  } else {
                    if (variationUsed === null) {
                      variationUsed = null;
                      price = null;
                      subscriptionCardUsed = null;
                    }
                  }
                }
              }
            }
          }
        }
      }

      return subscriptionCardUsed;
    },
    onCustomerAdded(customer) {
      this.dropDownKey++;
      this.fromKey++;
      this.addParticipantRecentlyCreated(customer);
      this.refreshParticipants = !this.refreshParticipants;
    },
    addVisitor() {
      const data = {
        client: null,
        subscriptionCard: null,
        booking: this.booking['@id']
      };
      postParticipant(data)
        .finally(() => {
          this.loadBookingParticipants();
        })
      ;
    },
    addParticipantRecentlyCreated(customer) {
      const data = {
        client: customer['@id'],
        subscriptionCard: null,
        booking: this.booking['@id']
      };

      postParticipant(data)
        .finally(() => {
          this.loadBookingParticipants();
        })
      ;
    },
    onParticipantDeleted() {
      this.reloadBookingParticipants();
      if (this.items.length === 0) {
        this.booking.name = this.$store.getters["auth/currentClub"].name;
      }
    },
    reloadBookingParticipants() {
      getBooking(this.booking.id)
        .then((response) => {
          hydrate(this.booking, response.data);
          this.updateBookingName();
        })
      ;
    },
    resizeTableRows() {
      this.totalRows = this.items.length;
    },
    hasOnlyParticipants() {
      for (const participantItem of this.items) {
        if (participantItem.client !== null || participantItem.user !== null) {
          return true;
        }
      }

      return false;
    },
    updateBookingName() {
      if (false === this.booking.nameManuallyUpdated || this.booking.nameManuallyUpdated === null) {
        if (this.items.length === 0) {
          if (this.booking.activityType !== LESSON_BOOKING_TYPE) {
            this.booking.name = this.$store.getters["auth/currentClub"].name;
          }
        } else {
          if (this.booking.activityType !== LESSON_BOOKING_TYPE) {
            this.booking.name = '';
            for (const index in this.items) {
              if (this.items[index].participant) {
                if (this.booking.name === '') {
                  this.booking.name += this.getParticipantName(this.items[index].participant.item);
                } else {
                  this.booking.name += ' / ' + this.getParticipantName(this.items[index].participant.item);
                }
              }
            }
            if (this.booking.name === '') {
              this.booking.name = this.$store.getters["auth/currentClub"].name;
            }
          }
        }
      }
    },
    getParticipantName(participantDetails) {
      return participantDetails.lastName ? capitalize(participantDetails.lastName) : capitalize(participantDetails.firstName);
    },
    loadBookingParticipants() {
      this.items = [];
      this.isBusy = true;

      getParticipantsByBooking(this.booking.id)
        .then((response) => {
          for (const customer of response.data['hydra:member']) {
            if (!customer.canceled) {
              const participant = new Participant(customer.client);
              participant.iriParticipant = customer['@id'];
              participant.canceled = customer.canceled;
              participant.category = customer.category;
              participant.bookingOwner = customer.bookingOwner;
              participant.phoneNumber = isNotUndefinedAndNotNull(customer.client) ? customer.client.phoneNumber : '-';
              participant.confirmedParticipation = isNotUndefinedAndNotNull(customer.confirmed) ? customer.confirmed : null;
              participant.subscriptionCardUsed = customer.subscriptionCard;
              if (this.booking.activityType === LEISURE_BOOKING_TYPE && participant.category === null) {
                participant.colToPay = '- ' + this.$currency;
                participant.colPaid = '- ' + this.$currency;
              } else {
                participant.colToPay = toFloatFixed(customer.price) + this.$currency;
                participant.colPaid = toFloatFixed(customer.price - customer.restToPay) + this.$currency;
              }
              participant.participantSubscription = customer.subscriptionCard ? customer.subscriptionCard.name : '-';
              participant.actions = customer.user || customer.client ? ['details', 'pay', 'delete'] : ['delete'];

              if (customer.client || customer.user) {
                participant.client = customer.client;
                participant.user = customer.user;
                participant.about = customer.client.fullName;
                participant.participant = {
                  label: customer.client.fullName,
                  code: customer.client['@id'],
                  item: customer.client,
                  iri: customer.client['@id']
                };
                participant.partcipantPreview = {
                  photo: this.avatar(customer),
                  values: customer.client
                }
              } else {
                participant.participant = null;
                participant.about = this.$t('components.custom.planning.booking-details-modal.general-information.visitor');
                participant.partcipantPreview = {
                  photo: null,
                  values: {
                    firstName: 'I', lastName: 'N'
                  }
                }
              }

              this.items.push(participant);

            } else {
              this.alreadyCanceled.push(customer);
            }
          }
        })
        .finally(() => {
          this.isBusy = false;
          this.reloadBookingParticipants();
          this.resizeTableRows()
        })
      ;
    },
    avatar(participant) {
      if (participant.client) {
        if (participant.client.identityPhoto) {
          return participant.client.identityPhoto;
        } else {
          if (participant.user) {
            if (participant.user.avatar) {
              return participant.user.avatar;
            }
          }
        }
      }
      return null;
    },
  },
  created() {
    this.loadBookingParticipants();
  },
  mounted() {
    this.$bus.$on('on:participant-confirmation:update', () => {
      let confirmedParticipantsCount = 0;
      let unconfirmedParticipantsCount = 0;

      for (const item of this.items) {
        item.confirmedParticipation ? confirmedParticipantsCount++ : unconfirmedParticipantsCount++;
      }

      this.booking.confirmedParticipantsCount = confirmedParticipantsCount;
      this.booking.unconfirmedParticipantsCount = unconfirmedParticipantsCount;
    });

    this.$bus.$on('on:refresh-participants', (participant) => {
      _put(participant.iriParticipant, {category: participant.category})
        .then(() => {
          this.loadBookingParticipants();
        })
      ;
    });
  },
  beforeDestroy() {
    try {
      this.$bus.$off('on:refresh-participants');
      this.$bus.$off('on:participant-confirmation:update');
    } catch (e) {
    }
  }
}
</script>
<style scoped lang="scss">
@import "@lazy/bookings/participants/_participants-header.scss";
@import "@lazy/planning/_mobile-buttons-add-new.scss";

.participants-container {
  height: 300px;
}
</style>
